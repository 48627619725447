<template>
  <el-dialog
    title="選擇子項目"
    :visible="true"
    width="600px"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <header class="flex" style="gap: 16px">
      <el-input
        v-model="search.name"
        placeholder="請輸入服務項目名稱"
        empty-text="暫無數據"
        clearable
      >
        <i slot="suffix" class="el-input__icon el-icon-search" />
      </el-input>

      <el-select v-model="search.service" clearable placeholder="選擇所屬的服務項目" empty-text="暫無數據">
        <el-option
          v-for="service in categorysData"
          :key="service.id"
          :value="service.id"
          :label="service.name"
        />
      </el-select>
    </header>

    <main class="select-area">
      <div class="border-b border-gray-40">
        <p class="select-area-title">選擇服務項目</p>
      </div>
      <div style="padding: 8px">
        <el-checkbox-group v-model="selected" class="item-group">
          <!-- <div class="flex flex-col flex-wrap" style="max-height: 230px"> -->
          <template v-for="(item, index) in chunkData[tableOptions.page - 1]">
            <div v-if="item.type === 'sub-service'" :key="item.id" style="padding-left: 15px">
              <el-checkbox :label="item.id" @change="onServiceCheck($event, item)">{{ item.name }}</el-checkbox>
            </div>
            <div v-else :key="'label' + index" class="border-b border-primary-100 mr-[30px]">
              <span class="inline text-[14px] text-primary-100 font-medium">
                服務項目：{{ item.name }}
              </span>
            </div>
          </template>
          <!-- </div> -->
        </el-checkbox-group>
      </div>
      <div>
        <Pagination
          :curPage.sync="tableOptions.page"
          :pageLimit="tableOptions.pageLimit"
          :total="flattenData.length"
        />
      </div>
    </main>

    <div class="flex justify-end items-center" style="padding-top: 26px">
      <el-button plain @click="$emit('close')">取消</el-button>
      <el-button type="primary" :disabled="!selected.length" @click="onSubmit">
        確認
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { defineComponent, onMounted, reactive, ref, computed } from 'vue'
import { map, chunk, get, flattenDeep, filter, uniq, some } from 'lodash'
import { useTable } from '@/use/table'

export default defineComponent({
  name: 'SubServiceAdvanceSelector',
  props: {
    typeOptions: {
      type: Object,
      default: () => ({ none: { label: '暫無資料', value: 'none' } }),
    },
    data: { type: Array, default: () => [] },
    syncData: { type: Array, default: () => [] },
    multiple: { type: Boolean, default: false },
    max: { type: [Number, null], default: null },
  },
  emits: ['close', 'confirm'],
  setup (props, { emit }) {
    const { tableData, tableDataCount, tableOptions } = useTable()
    tableOptions.pageLimit = 20

    const search = reactive({
      name: null,
      service: null,
    })
    const selected = ref([])
    const activeList = ref([])

    const categorysData = computed(() => {
      return map(props.data, i => ({ id: i.id, name: i.name }))
    })

    const flattenData = computed(() => {
      let data = props.data.reduce((acc, curr) => {
        acc.push({ id: curr.id, name: curr.name, type: 'service' })
        curr.subServices.forEach(service => {
          acc.push({ id: service.id, name: service.name, service: curr.id, type: 'sub-service' })
        })
        return acc
      }, [])

      if (search.name) data = filter(data, (i) => i.name.includes(search.name))
      if (search.service) data = filter(data, (i) => i.id === search.service || i.service === search.service)

      return data
    })

    const chunkData = computed(() => chunk(flattenData.value, tableOptions.pageLimit))

    const onSubmit = () => {
      emit('confirm', activeList.value)
      emit('close')
    }

    const onServiceCheck = (check, service) => {
      if (check) {
        activeList.value.push(service)
      } else if (!check) {
        activeList.value = filter(activeList.value, (item) => item.id !== service.id)
      }
    }

    onMounted(() => {
      tableData.value = props.data
      tableDataCount.value = props.data.length
      activeList.value = [...props.syncData]
      selected.value = [...map(props.syncData, 'id')]
    })

    return {
      get,
      flattenDeep,
      onSubmit,
      search,
      selected,
      tableDataCount,
      tableOptions,
      flattenData,
      chunkData,
      onServiceCheck,
      categorysData,
      activeList,
    }
  },
})
</script>

  <style scoped lang="postcss">
  .grid-container {
    @apply grid gap-[36px];
    @apply text-normal;
    grid-template-columns: 1fr 75px;
  }

  /* .item-group {
  @apply grid grid-flow-col gap-3;
  grid-template-rows: repeat(10, minmax(0, 1fr));
  > * {
    line-height: 14px;
  }
} */

.item-group  {
    @apply flex flex-col  overflow-y-auto max-h-[500px];
  }

  ::v-deep .el-input {
    @apply w-full;
  }
  ::v-deep .el-select {
    @apply w-full;
  }

  .select-area {
    @apply border border-gray-40 mt-[12px] rounded-[4px];
  }
  .select-area-title {
    @apply p-[8px] text-base font-medium text-sub;
  }

  .divider {
    @apply mt-[8px] mb-[20px];
  }
  </style>
